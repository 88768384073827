const header = () => {
  try {
    const header = document.querySelector('.opening'),
          dropmenu = document.querySelector('.dropmenu'),
          trigger = document.querySelector('.humburger-subheader'),
          triggerDropmenu = document.querySelector('.dropdown');
    
    trigger.addEventListener('click', ()=> {
      header.classList.toggle('updown');
      trigger.classList.toggle('active');
    });

  } catch(e) {}
};

export default header;