const humburger = ()=> {
  try {
    const humburger = document.querySelector('.humburger-mobile'),
          subMenuBtn = document.querySelectorAll('.subheader-links__parent-title'),
          subMenu = document.querySelector('.opening'),
          dropmenu = document.querySelector('.subheader-dropdown'),
          menu = document.querySelector('.subheader-items'),
          search = document.querySelector('.Search'),
          back = document.querySelectorAll('.button_back');

    humburger.addEventListener('click', () => {
      menu.classList.toggle('hum');

      humburger.classList.toggle('active');

      search.classList.remove('Search-change');
    });

    if (window.matchMedia("(max-width: 992px)").matches) {

      subMenuBtn.forEach((item, i) => {
        item.addEventListener('click', function(e) {
          e.preventDefault();

          this.parentNode.lastElementChild.classList.add('subhum');
        });
      });

      back.forEach((item, i) => {
        item.addEventListener('click', function() {

          this.closest('.subheader-dropdown').classList.remove('subhum');
        });
      })

    }

  } catch(e) {}
};

export default humburger;
