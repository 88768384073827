const slick = () => {
  try {
    /* Similar slider */
    $('.similar-block').slick({
      autoplay: false,
      autoplaySpeed: 1500,
      prevArrow: `
        <div class="arrow-left">
          <button class="button button_left">
              <svg><use href="#arrow"></use></svg>
          </button>
       </div>
      `,
      nextArrow: `
        <div class="arrow-right">
          <button class="button button_right">
              <svg><use href="#arrow"></use></svg>
          </button>
        </div>
      `,
      centerMode: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 993,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 772,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    });
  } catch(e) {}
};

export default slick;