const search = () => {
  const search = document.querySelector('.Search'),
        logo = document.querySelector('.subheader-logo'),
        container = document.querySelector('.subheader-main'),
        humburger = document.querySelector('.humburger-mobile'),
        menu = document.querySelector('.subheader-items'),
        trigger = document.querySelector('.button_search-up');

  trigger.addEventListener('click', (e)=> {
    e.preventDefault();

    search.classList.toggle('Search-change');

    humburger.classList.remove('active');
    menu.classList.remove('hum')
  });
};

export default search;
