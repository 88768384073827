import catigoriesMenu from "./modules/catigoriesMenu";
import header from "./modules/header";
import humburger from "./modules/humburger";
import modal from "./modules/modal";
import search from "./modules/search";
import slick from "./modules/slick";
import slider from "./modules/slider";
import tabs from "./modules/tabs";

document.addEventListener("DOMContentLoaded", () => {
  slider();
  modal(".button-popup", ".popup-call", ".popup-close", `popup-overlay`);
  modal(
    ".button-order",
    ".popup-order",
    ".popup-order__close",
    `popup-overlay__order`
  );
  slick();
  tabs();
  header();
  humburger();
  search();
  catigoriesMenu();
});

const initWorkSwiper = () => {
  if (window.matchMedia("(max-width: 1024px)").matches) {
    new Swiper(".work-swiper", {
      slidesPerView: "auto",
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }
};

window.addEventListener("resize", () => {
  if (window.matchMedia("(max-width: 1024px)").matches) {
    initWorkSwiper();
  }
});

initWorkSwiper();
