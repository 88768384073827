const catigoriesMenu = () => {
  try {
    const trigger = document.querySelector('.button-catalog'),
          menu = document.querySelector('.wrapper-menu'),
          close = document.querySelector('.wrapper-menu__close')

    trigger.addEventListener('click', (e) => {
      menu.classList.add('wrapper-active')
    })

    close.addEventListener('click', (e) => {
      menu.classList.remove('wrapper-active')
    })
  } catch(e) {}
}

export default catigoriesMenu;