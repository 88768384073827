import calcScroll from './calcScroll';

const modal = (triggerSelector, modalSelector, closeSelector, overlaySelector) => {
  try {
    const triggers = document.querySelectorAll(triggerSelector),
        modal = document.querySelector(modalSelector),
        close = document.querySelector(closeSelector);

    triggers.forEach(trigger => {
      trigger.addEventListener('click', (e) => {
        e.preventDefault();

        modal.style.cssText = `display: block;`;

        document.body.style.overflow = 'hidden';
        document.body.style.marginRight = calcScroll();
      });
    });



    modal.addEventListener('click', (e) => {
      const target = e.target;

      if(target.classList.contains(overlaySelector)) {
          document.body.style.overflow = '';

          modal.style.cssText = `display: none;`;
          document.body.style.marginRight = `0px`;
      }
    });

    close.addEventListener('click', (e) => {
      document.body.style.overflow = '';

      modal.style.cssText = `display: none;`;

      document.body.style.marginRight = `0px`;
    });
    } catch(e) {}



};

export default modal;