const slider = () => {
  try {
    const rightBtn = document.querySelector(".arrow-right"),
      leftBtn = document.querySelector(".arrow-left"),
      visibility = document.querySelector(".visibility"),
      overview = document.querySelector(".overview"),
      dataItemBig = document.querySelectorAll('[data-item="big"]'),
      dataItemSmall = document.querySelectorAll('[data-item="small"]'),
      screenWidth = window.outerWidth;
    let offset = 0,
      bigItemCount = 0,
      smallItemCount = 0,
      bigItemWidth = 0,
      smallItemWidth = 0,
      blockWidth = 0,
      hiddenContent = 0;

    dataItemBig.forEach((item) => {
      bigItemCount++;
      bigItemWidth = Math.round(
        window.getComputedStyle(item).width.replace(/\px/g, "")
      );
    });

    dataItemSmall.forEach((item) => {
      smallItemCount++;
      smallItemWidth = Math.round(
        window.getComputedStyle(item).width.replace(/\px/g, "")
      );
    });

    function calcWidth() {
      const countBlocks = bigItemCount + smallItemCount;
      const result =
        bigItemCount * (bigItemWidth * 2) + smallItemCount * smallItemWidth;

      if (countBlocks % 2 == 0) {
        overview.style.cssText = `width: ${(result + smallItemWidth) / 2}px;`;
        blockWidth = (result + smallItemWidth) / 2;
      } else {
        overview.style.cssText = `width: ${result / 2}px;`;
        blockWidth = result / 2;
      }
    }
    calcWidth();

    function calcOffset() {
      hiddenContent =
        blockWidth -
        Math.round(
          window.getComputedStyle(visibility).width.replace(/\px/g, "")
        );
    }
    calcOffset();

    rightBtn.addEventListener("click", () => {
      if (screenWidth <= 772) {
        if (smallItemCount % 2 === 0) {
          offset -= (hiddenContent / smallItemCount) * 2;
        } else {
          offset -= (hiddenContent / (smallItemCount + bigItemCount)) * 2;
        }
      } else {
        offset -= hiddenContent / 2;
      }

      overview.style.cssText = `
      transition: .5s all;
      transform: translateX(${offset}px);
      width: ${blockWidth}px;
    `;

      showBtn();
    });

    leftBtn.addEventListener("click", () => {
      if (screenWidth <= 772) {
        if (smallItemCount % 2 === 0) {
          offset += (hiddenContent / smallItemCount) * 2;
        } else {
          offset += (hiddenContent / (smallItemCount + bigItemCount)) * 2;
        }
      } else {
        offset += hiddenContent / 2;
      }

      overview.style.cssText = `
      transition: .5s all;
      transform: translateX(${offset}px);
      width: ${blockWidth}px;
    `;

      showBtn();
    });

    function showBtn() {
      if (offset < -4) {
        leftBtn.style.display = "block";
      } else {
        leftBtn.style.display = "none";
      }

      if (offset <= -hiddenContent) {
        rightBtn.style.display = "none";
      } else {
        rightBtn.style.display = "block";
      }
    }

    showBtn();

    $(function () {
      $(".slider").swipe({
        swipe: function (
          event,
          direction,
          distance,
          duration,
          fingerCount,
          fingerData
        ) {
          if (direction === "left") {
            if (!(offset <= -hiddenContent)) {
              rightBtn.click();
            }
          }
          if (direction === "right") {
            if (offset < -4) {
              leftBtn.click();
            }
          }
          console.log(duration);
        },
        swipeStatus: function (
          event,
          phase,
          direction,
          distance,
          duration,
          fingerCount
        ) {
          if (direction === "left") {
            if (!(offset <= -hiddenContent)) {
              overview.style.cssText = `
                transition: .5s all;
                transform: translateX(${offset - distance}px);
                width: ${blockWidth}px;
              `;
            }
          }
          if (direction === "right") {
            if (offset < -4) {
              overview.style.cssText = `
                transition: .5s all;
                transform: translateX(${offset + distance}px);
                width: ${blockWidth}px;
              `;
            }
          }
        },
      });
    });
  } catch (e) {}
};

export default slider;
