function tabs() {
  try {
    const tabContainer = document.querySelector('.aboutus-btns'),
          tabs = document.querySelectorAll('[data-btn]'),
          items = document.querySelectorAll('[data-item]');

    tabContainer.addEventListener('click', (e) => {
      const target = e.target;

      if(target.classList.contains('button_tab')) {
        
        tabs.forEach((item,i) => {
          
          if(target === item) {
            changeActiveClass(i);

            hideContent();
            showContent(i);
          }
        });
      }
    });

    function hideContent() {
      items.forEach(item => {
        item.classList.remove('show');
        item.classList.add('hide');
      });
    }

    function showContent(i = 0) {
      items[i].classList.remove('hide');
      items[i].classList.add('show');
    }

    function changeActiveClass(i = 0) {
      tabs.forEach(item => {
        item.classList.remove('button_tab-active');
      });

      tabs[i].classList.add('button_tab-active');
    }

    changeActiveClass();

    hideContent();
    showContent();
  } catch(e) {}
}

export default tabs;