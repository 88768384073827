const calcScroll = () => {
  const div = document.createElement('div');

  div.style.cssText = `
      width: 100px;
      height: 100px;
      overflow: scroll;
      visibility: hidden;
  `;

  document.body.append(div);

  let scrollWidth = div.offsetWidth - div.clientWidth;

  div.remove();

  return scrollWidth + 'px';
};

export default calcScroll;